import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
export const About = (props) => {
  return (
    <div id="about">
      <div className="container">
{/*         <div className="row">
          <div className="col-xs-12 col-md-6">
            {" "}
            <img
              src="img/about/about.png"
              className="img-responsive"
              alt=""
            />{" "}
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="about-text">
              <h2>{props.data ? props.data.head : "loading..."}</h2>
              <p>{props.data ? props.data.paragraph : "loading..."}</p>
              <h2>{props.data ? props.data.head1 : "loading..."}</h2>
              <div className="list-style">
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>
                    {props.data
                      ? props.data.Why.map((d, i) => (
                          <li key={`${d}-${i}`}>{d}</li>
                        ))
                      : "loading"}
                  </ul>
                </div>
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>
                    {props.data
                      ? props.data.Why2.map((d, i) => (
                          <li key={`${d}-${i}`}> {d}</li>
                        ))
                      : "loading"}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div> */}
       
      

{/*        <div className='row' style={{marginTop:'50px'}}> */}
<div className='row' style={{marginTop:'100px'}}>
       <Carousel autoPlay emulateTouch infiniteLoop
       showThumbs=''
       showStatus=''
       >
       <div >
           <img
             src="img/Present-Move/Presentation-01.jpg"
             className="img-responsive"
             style={{width: "1000px",borderRadius:'20px'}}
             alt=""
           />
          
         </div>
        <div  >
           <img
             src="img/Present-Move/Presentation-02.jpg"
             className="img-responsive"
              style={{ width: "1000px",borderRadius:'20px' }}
             alt=""
           />
          
         </div>
         <div  >
           <img
             src="img/Present-Move/Presentation-03.jpg"
             className="img-responsive"
              style={{ width: "1000px",borderRadius:'20px' }}
             alt=""
           />
          
         </div>
         <div  >
           <img
             src="img/Present-Move/Presentation-04.jpg"
             className="img-responsive"
              style={{ width: "1000px",borderRadius:'20px' }}
             alt=""
           />
          
         </div>
         <div  >
           <img
             src="img/Present-Move/Presentation-05.jpg"
             className="img-responsive"
              style={{ width: "1000px",borderRadius:'20px' }}
             alt=""
           />
          
         </div>
         <div  >
           <img
             src="img/Present-Move/Presentation-06.jpg"
             className="img-responsive"
              style={{ width: "1000px",borderRadius:'20px' }}
             alt=""
           />
          
         </div>
         <div  >
           <img
             src="img/Present-Move/Presentation-07.jpg"
             className="img-responsive"
              style={{ width: "1000px",borderRadius:'20px' }}
             alt=""
           />
          
         </div>
         <div  >
           <img
             src="img/Present-Move/Presentation-08.jpg"
             className="img-responsive"
              style={{ width: "1000px",borderRadius:'20px' }}
             alt=""
           />
          
         </div>
         <div  >
           <img
             src="img/Present-Move/Presentation-09.jpg"
             className="img-responsive"
              style={{ width: "1000px",borderRadius:'20px' }}
             alt=""
           />
          
         </div>
         <div  >
           <img
             src="img/Present-Move/Presentation-10.jpg"
             className="img-responsive"
              style={{ width: "1000px",borderRadius:'20px' }}
             alt=""
           />
          
         </div>
         <div  >
           <img
             src="img/Present-Move/Presentation-11.jpg"
             className="img-responsive"
              style={{ width: "1000px",borderRadius:'20px' }}
             alt=""
           />
          
         </div>
         <div  >
           <img
             src="img/Present-Move/Presentation-12.jpg"
             className="img-responsive"
              style={{ width: "1000px",borderRadius:'20px' }}
             alt=""
           />
          
         </div>                           
       </Carousel>


       </div>
      </div>
     
   
    </div>
  );
};
