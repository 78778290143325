import { Link } from "react-router-dom";
import Axios from "axios";
import React, { useEffect, useState } from "react";
import Price from "./Pricemarket";
import useWindowSize from "../hooks/useWindowSize";

export const Navigation = (props) => {
  const width = useWindowSize();
  const hidden = width <= 1366;

  function showAlert() {
    document
      .getElementsByClassName("alert")[0]
      .setAttribute(
        "style",
        "visibility:'none'; width:100%;height:71px; font-size:20px;width:100%"
      );
    setTimeout(() => {
      document
        .getElementsByClassName("alert")[0]
        .setAttribute(
          "style",
          "visibility:hidden; height:71px; font-size:20px; opacity: 0;transition: visibility 0s linear 0.5s, opacity 0.5s linear;"
        );
    }, 500);
  }

  //GET VEMO Coin price from dex-trade api
  const [price, set_price] = useState(0);
  async function Get_price(time) {
    let volume = 0;
    const startTime = time - (time % 86400000);
    const endTime = startTime + 86400000;
    await Axios.get(
      "https://api.dex-trade.com/v1/public/trades?pair=VEMOUSDT"
    ).then(async (res) => {

      await Promise.all(
        res.data.data.map((res) => {
          // console.log(res.timestamp, startTime);
          if (
            res.type === "BUY" &&
            res.timestamp * 1000 >= startTime &&
            res.timestamp * 1000 < endTime
          ) {
            volume += parseFloat(res.volume);
          }
          console.log(volume);
        })
      );

      set_price(volume * res.data.data[0].rate);
    });
  }

  useEffect(() => {
    let time = Date.now();

    Get_price(time);
  }, []);

  console.log(price);


  useEffect(() => {
/*     const el_inlist = document.getElementById('donate-wallet-inlist')
    const el_outlist = document.getElementById('donate-wallet-outlist') */
    const el_vemo_inlist = document.getElementById('vemo-wallet-inlist')
    const el_vemo_outlist = document.getElementById('vemo-wallet-outlist')
    if (!hidden) {
/*       el_outlist.style.visibility = 'visible'
      el_inlist.style.visibility = 'hidden'
      el_inlist.style.width = '0'
      el_outlist.style.width = '232.69' */
      el_vemo_outlist.style.visibility = 'visible'
      el_vemo_inlist.style.visibility = 'hidden'
      el_vemo_inlist.style.width = '0'
      el_vemo_outlist.style.width = '232.69'
    } else {
/*       el_outlist.style.visibility = 'hidden'
      el_inlist.style.visibility = 'visible'
      el_inlist.style.width = '232.69'
      el_outlist.style.width = '0' */
      el_vemo_outlist.style.visibility = 'hidden'
      el_vemo_inlist.style.visibility = 'visible'
      el_vemo_inlist.style.width = '232.69'
      el_vemo_outlist.style.width = '0'
    

    }

  }, [hidden])
  return (
    <div className="navbar-fixed-top">
      <nav
        id="menu"
        className="navbar navbar-default "
        style={{ position: "relative", marginBottom: "0px" }}
      >
        <div
          className="alert alert-success navbar-fixed-top"
          role="alert"
          align="center"
          style={{ visibility: "hidden", height: "71px" }}
        >
          copy succsess
        </div>

        {/* <div className='container'> */}
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            {" "}
            {/* <span className='sr-only'>Toggle navigation</span>{' '} */}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
          </button>
          {/* <img src="img/1.jpg" alt="" className="logo"/> */}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-end",
            }}
          >
            <a className="navbar-brand page-scroll" href="#page-top">
              <h2>METAREUM</h2>
            </a>{" "}
            {/* <div style={{ color: "white", textAlign: "center" }}>
              Charity donation volume
              <p
                className='blink_me'
                style={{
                  color: "white",
                  margin: "0 20%",
                  borderRadius: "20px",
                  backgroundColor: "#32cae7",
                  padding: "3px 8px",

                }}
              >
                $ {((0.001 * parseFloat(price)) + 20).toFixed(3)}

              </p>
            </div> */}
            {/* <div style={{ color: "white", textAlign: "center" }}>
              Official Wallet for Donate at{" "}
              <p
                style={{ color: "#32CAE7", margin: "0 5px", fontSize: "12px" }}
              >
                0x26DC2816843dFA0e3EF2c6b62795417EDbE0c7f2
              </p>
            </div> */}



            {/* <div id='donate-wallet-outlist' className="dropdown " style={{ margin: "9px 0 0 0" }}>
              <li
                className="btn btn-secondary dropdown-toggle"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
              >

                <div style={{ display: 'flex', flexDirection: 'row', fontSize: "12px", color: "#32CAE7" }}>
                  <img src="img/icon-donat-wallet.png" alt="" style={{ width: '34px' }} />
                  <div style={{ textAlign: 'start' }}>Official Wallet for Donate at{" "}<br />0x...c7f2</div>

                </div>
              </li>
              <div
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton"
                style={{ width: "400px", left: 'auto' }}
              >
                <li style={{}}>
                  <a
                    className="dropdown-item"
                    onClick={() => {
                      navigator.clipboard.writeText(props.data.donation);
                      showAlert();
                    }}
                    style={{ paddingRight: "6%" }}
                  >
                    {props.data ? props.data.donation : "Loading"}{" "}
                    {props.data3
                      ? props.data3.map((d, i) => (
                        <div
                          key={`${d.title}-${i}`}
                          style={{ paddingTop: "3px", paddingLeft: "10px" }}
                        >
                          <i
                            className={d.icon}
                            style={{ color: "grey" }}
                          ></i>
                        </div>
                      ))
                      : "Loading..."}
                  </a>
                </li>
              </div>
            </div> */}

            <div id='vemo-wallet-outlist' className="dropdown " style={{ margin: "9px 0 0 0" }}>
              <li
                className="btn btn-secondary dropdown-toggle"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
              >

                <div style={{ display: 'flex', flexDirection: 'row', fontSize: "12px", color: "#32CAE7" }}>



                  <i className='fas fa-wallet' style={{ color: "white", marginRight: '3px', fontSize: '26px' }}></i>

                  <div style={{ textAlign: 'start' }}>METAREUM Contract address{" "}<br />0x....f798</div>

                </div>
              </li>
              <div
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton"
                style={{ width: "400px", left: 'auto' }}
              >
                <li style={{}}>
                  <a
                    className="dropdown-item"
                    onClick={() => {
                      navigator.clipboard.writeText(props.data.wallet);
                      showAlert();
                    }}
                    style={{ paddingRight: "6%" }}
                  >
                    {props.data ? props.data.wallet : "Loading"}{" "}
                    {props.data3
                      ? props.data3.map((d, i) => (
                        <div
                          key={`${d.title}-${i}`}
                          style={{ paddingTop: "3px", paddingLeft: "10px" }}
                        >
                          <i
                            className={d.icon}
                            style={{ color: "grey" }}
                          ></i>
                        </div>
                      ))
                      : "Loading..."}
                  </a>
                </li>
              </div>
            </div>

          </div>
        </div>

        {/* <div className='navbar-header col'>
            
              <span className='navbar-brand' style={{marginTop:'10px',marginRight:'0px', color:'white'}}>
                Charity donation volume
              </span>
              <span className='navbar-brand' style={{marginTop:'10px',marginRight:'0px', color:'cyan'}}>
                {( 0.001 * 100 * (parseFloat(price)) )}
              </span>
              <span className='navbar-brand' style={{marginTop:'10px',marginRight:'0px', color:'white'}}>
                Dollar.
              </span>
            
          </div> */}

        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          <ul
            className="nav navbar-nav navbar-right"
            style={{ marginTop: "10px", marginRight: "0px" }}
          >
            <li className="mydropdown">
              <div id='donate-wallet-inlist' className="dropdown " style={{ margin: "2px 0 0 20px", visibility: 'hidden' }}>
                <li
                  className="btn btn-secondary dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                >

                  <div style={{ display: 'flex', flexDirection: 'row', fontSize: "12px", color: "#32CAE7" }}>
                    <img src="img/icon-donat-wallet.png" alt="" style={{ width: '34px' }} />
                    <div style={{ textAlign: 'start' }}>Official Wallet for Donate at{" "}<br />0x...c7f2</div>

                  </div>
                </li>
                <div
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuButton"
                  style={{ width: "400px", left: 'auto' }}
                >
                  <li style={{}}>
                    <a
                      className="dropdown-item"
                      onClick={() => {
                        navigator.clipboard.writeText(props.data.donation);
                        showAlert();
                      }}
                      style={{ paddingRight: "6%" }}
                    >
                      {props.data ? props.data.donation : "Loading"}{" "}
                      {props.data3
                        ? props.data3.map((d, i) => (
                          <div
                            key={`${d.title}-${i}`}
                            style={{ paddingTop: "3px", paddingLeft: "10px" }}
                          >
                            <i
                              className={d.icon}
                              style={{ color: "grey" }}
                            ></i>
                          </div>
                        ))
                        : "Loading..."}
                    </a>
                  </li>
                </div>
              </div>
            </li>

            <li className="mydropdown">
              <div id='vemo-wallet-inlist' className="dropdown" style={{ margin: "2px 0 0 20px" , visibility: 'hidden' }}>
                <li
                  className="btn btn-secondary dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                >

                  <span style={{ fontSize: "12px", color: "#32CAE7" }}>
                    {props.data2
                      ? props.data2.map((d, i) => (
                        <div key={`${d.title}-${i}`} style={{ display: 'flex', flexDirection: 'row', textAlign: 'start' }}>

                          <i className={d.icon} style={{ color: "white", marginRight: '3px', fontSize: '26px' }}></i>

                          METAREUM Contract address <br />0xc....2FA
                        </div>
                      ))
                      : "Loading..."}{" "}
                  </span>
                </li>
                <div
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuButton"
                  style={{ width: "400px", left: 'auto' }}
                >
                  <li style={{}}>
                    <a
                      className="dropdown-item"
                      onClick={() => {
                        navigator.clipboard.writeText(props.data.wallet);
                        showAlert();
                      }}
                      style={{ paddingRight: "6%" }}
                    >
                      {props.data ? props.data.wallet : "Loading"}{" "}
                      {props.data3
                        ? props.data3.map((d, i) => (
                          <div
                            key={`${d.title}-${i}`}
                            style={{ paddingTop: "3px", paddingLeft: "10px" }}
                          >
                            <i
                              className={d.icon}
                              style={{ color: "grey" }}
                            ></i>
                          </div>
                        ))
                        : "Loading..."}
                    </a>
                  </li>
                </div>
              </div>
            </li>


{/*             <li className="mydropdown">
              <div className="dropdown">
                <li
                  className="dropdown-toggle"

                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                >
                  <div className='platform-box'>

                    <p
                      className='blink_me2'

                    >
                      Platform

                    </p>
                  </div>
                </li>
                <div
                  className="nav-dropdown-platform"
                  aria-labelledby="dropdownMenuButton"
style={{ backgroundColor:'#232843'}}
                >
                  <li style={{ height: '25px', paddingLeft: '10px'}}>
                    <a href='https://vemoswap.vemo.world/'
                      className="dropdown-item" style={{ color: 'white' }}
                    >
                      DeFi
                    </a>
                  </li>
                  <li style={{ height: '25px', paddingLeft: '10px' }}>
                    <a href='https://vemoassetboard.vemo.world/'
                      className="dropdown-item" style={{ color: 'white' }}
                    >
                      Board
                    </a>
                  </li>
                  <li style={{ height: '25px', paddingLeft: '10px' }}>
                    <a href='https://nft.vemo.world/'
                      className="dropdown-item" style={{ color: 'white' }}
                    >
                      NFT
                    </a>
                  </li>
                </div>
              </div>
            </li> */}
{/*             <li>
              <a href="#features" className="page-scroll">
                {props.data ? props.data.feat : "Loading"}
              </a>
            </li> */}
            <li>
              <a href="#about" className="page-scroll">
                {props.data ? props.data.about : "Loading"}
              </a>
            </li>
            {/* <li>
                <a href='#concept' className='page-scroll'>
                  {props.data ? props.data.concept : 'Loading'}
                </a>
              </li> */}
{/*             <li>
              <a href="#tokenomic" className="page-scroll">
                {props.data ? props.data.token : "Loading"}
              </a>
            </li>
            <li>
              <a href="#service" className="page-scroll">
                {props.data ? props.data.service : "Loading"}
              </a>
            </li>
            <li>
              <a href="#roadmap" className="page-scroll">
                {props.data ? props.data.road : "Loading"}
              </a>
            </li>
            <li>
              <a href="#faqs" className="page-scroll">
                {props.data ? props.data.faq : "Loading"}
              </a>
            </li>
            <li>
              <a href="#team" className="page-scroll">
                Our Team
              </a>
            </li>
            <li>
              <a href="#portfolio" className="page-scroll">
                {props.data ? props.data.partner : "Loading"}
              </a>
            </li>
            <li>
              <a href="#media" className="page-scroll">
                {props.data ? props.data.media : "Loading"}
              </a>
            </li> */}
            {/* <li>
                <a href='#team' className='page-scroll'>
                  {props.data ? props.data.team : 'Loading'}
                </a>
              </li> */}
            <li>
              <a href="#contact" className="page-scroll">
                {props.data ? props.data.contact : "Loading"}
              </a>
            </li>
            {/* <li>
              <Link to="/whitepaper" className="">
                {props.data ? props.data.white : "Loading"}
              </Link>
            </li> */}
{/*             <li>
              <Link to="/Whitepaper" className="">
                {props.data ? props.data.white : "Loading"}
              </Link>
            </li> */}




            {/* <li className="mydropdown" >
   
<div className="dropdown" style={{margin:'2px 0 0 20px'}}>
                <button
                    className="btn btn-secondary dropdown-toggle" 
                    type="button" 
                    id="dropdownMenuButton" 
                    data-toggle="dropdown" 
                    aria-haspopup="true" >
                    EN/TH
                </button>
                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton" style={{width:'50px'}}>
                <li style={{display:'flex', flexDirection:'column'}}>
                    <a className="dropdown-item" onClick={() => {localStorage.setItem('lang','th'); window.location.reload(false);}} >TH</a>
                    <a className="dropdown-item" onClick={() => {localStorage.setItem('lang','en');  window.location.reload(false);}}>EN</a>
                </li>
                
                </div>
                </div>
    </li> */}
          </ul>
        </div>
      </nav>


      {/* <Price></Price> */}
    </div>
  );
};
